import { render, staticRenderFns } from "./RegisterBusiness.vue?vue&type=template&id=06bc425c&scoped=true"
import script from "./RegisterBusiness.vue?vue&type=script&lang=js"
export * from "./RegisterBusiness.vue?vue&type=script&lang=js"
import style0 from "./RegisterBusiness.vue?vue&type=style&index=0&id=06bc425c&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "06bc425c",
  null
  
)

export default component.exports